import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TemplateDialogConfirmComponent } from '@presentation/molecules/template-dialog-confirm/template-dialog-confirm.component';
import { TemplateDialogQuestionComponent } from '@presentation/molecules/template-dialog-question/template-dialog-question.component';
import { TemplateDialogCancelComponent } from '@presentation/molecules/template-dialog-cancel/template-dialog-cancel.component';
import { TemplateDialogNotificationComponent } from '@presentation/molecules/template-dialog-notification/template-dialog-notification.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    TemplateDialogConfirmComponent,
    TemplateDialogQuestionComponent,
    TemplateDialogCancelComponent,
    TemplateDialogNotificationComponent
  ],
  templateUrl: './app.component.html',
})
export class AppComponent {}
