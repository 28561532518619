import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ListVariableQuery, ListVariableResponse } from '@domain/services/variables/list-variable.dto';
import { VariableRepository } from '@domain/services/variables/variable.repository';
import { WriteVariablePayload, WriteVariableResponse } from '@domain/services/variables/write-variable.dto';
import { URL_BACKEND } from 'src/app/core/config/url';

@Injectable({
  providedIn: 'root',
})
export class VariableRepositoryService extends VariableRepository {
  urlApi = `${URL_BACKEND}/entity/variable`;

  constructor(private http: HttpClient) {
    super();
  }

  override list(query?: ListVariableQuery | undefined): Observable<ListVariableResponse> {
    return this.http.get<ListVariableResponse>(`${this.urlApi}/list`, {
      params: {...query},
    });
  }

  override create(payload: WriteVariablePayload): Observable<WriteVariableResponse> {
    return this.http.post<WriteVariableResponse>(this.urlApi, payload);
  }

  override update(payload: WriteVariablePayload): Observable<WriteVariableResponse> {
    return this.http.put<WriteVariableResponse>(this.urlApi, payload);
  }

  override delete(payload: number): Observable<any> {
    return this.http.delete(`${this.urlApi}?id=${payload}`);
  }

}