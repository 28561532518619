import { Injectable, inject } from "@angular/core";
import { CanActivateFn, Router } from '@angular/router';
import { AuthRepository } from '@domain/services/auth/repository/auth.repository';
import { AccessRouteNames } from "@presentation/pages/access/access.routenames";
import { UsersRouteNames } from "@presentation/pages/core/users/users.routenames";

@Injectable({
  providedIn: 'root'
})
class LoginGuardService {
  constructor(
    private _router: Router,
    private authLocalService: AuthRepository,
  ) {}

  canActivate(): boolean {
    if (this.authLocalService.isLogged()) {
      const isNewPassword = this.authLocalService.isNewPassword();
      this._router.navigateByUrl(isNewPassword ? AccessRouteNames.firstPassword.global : UsersRouteNames.list.global);
      return false;
    }
    return true;
  }
}

export const LoginGuard: CanActivateFn = (): boolean => {
  return inject(LoginGuardService).canActivate();
}